import React, { useEffect } from "react"
import { navigate } from "gatsby"

const Angebot: React.FC = () => {
  useEffect(() => {
    navigate("/angebot/sonnenbrillen/")
  })

  return null
}

export default Angebot
